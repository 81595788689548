<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="false" />
      <template v-if="isList">
        <ul class="nav nav-tabs customtab" role="tablist">
          <li class="nav-item"> <a class="nav-link active" id="main-nav" data-toggle="tab" href="#editor" role="tab" @click="changeTab('main')"><span
          class="hidden-sm-up"></span> <span class="hidden-xs-down">Career</span></a> </li>
          <li class="nav-item"> <a class="nav-link" id="seo-nav" data-toggle="tab" href="#seo" role="tab" @click="changeTab('SEO')"><span
          class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="editor" role="tabpanel">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Hero Image</h5>
                <small>Last updated : {{(row_hero.ahi_update_date||"").dates("format")}}</small>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="wrap_slider_img">
                      <div class="bullet-cta">
                        <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                          aria-controls="collapseExample" v-tooltip="'Edit Hero Image'" class="bullet_edit collapse_btn"><i class="fas fa-pencil-alt"></i></a>
                      </div>
                      <img
                        :src="uploader((row_hero.ahi_background || '').removeWebp(),'411')"
                        class="img-responsive" />
                      <div class="slider_name">
                        <p># {{row_hero.ahi_title}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                  <form v-on:submit.prevent="handleSubmit(doSubmitHero)">
                    <div class="row collapse mt-4" id="collapseExample">
                      <div class="col-md-8 mb-3">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="panel_group">
                              <div class="panel_heading">Hero Image Text</div>
                              <div class="panel_body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Title <span class="text-danger mr5">*</span></label>
                                      <input v-model="row_hero.ahi_title" type="text" class="form-control"
                                        placeholder="Title">
                                      <VValidate name="Title" v-model="row_hero.ahi_title" rules="required" />
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Description</label>
                                      <textarea class="form-control" v-model="row_hero.ahi_description" rows="4" placeholder="Description" rules="required"></textarea>
                                      <VValidate name="Description" v-model="row_hero.ahi_description" rules="required" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <Uploader v-model="row_hero.ahi_background" type="hero" label="Photo" />
                        <VValidate name="Hero Image" v-model="row_hero.ahi_background" rules="required" />
                        <div class="form-group">
                          <label class="control-label">Alt Img <span class="text-danger mr5">*</span></label>
                          <input v-model="row_hero.ahi_alt" type="text" class="form-control"
                            placeholder="Alt Img">
                          <VValidate name="Alt Img" v-model="row_hero.ahi_alt" rules="required" />
                        </div>
                        <button type="submit" class="btn btn-rounded btn-block btn-info mt-5">Submit</button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title">Career Lists</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="doFilter(),doSearch()" v-if="total_data>0">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <input v-model="filter.search" type="text" class="form-control" @input="checkDisabled"
                            placeholder="Type and Press Enter" required="" />
                          <div class="input-group-append">
                            <button class="btn btn-info" id="btn-search" disabled type="submit"><i class="fas fa-search"></i></button>
                          </div>
                            <button v-if="isSearch" v-tooltip="'Clear Search Results'" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                            </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row gutter-20 mt-4">
                  <!-- Column -->
                  <div v-if="data.data===false" class="col-md-12">
                    <Loader class="mt10 mb10 text-center d-block" />
                  </div>
                  <div v-if="data.data.length===0" class="col-md-12">
                    <h4 class="text-center">
                      {{ isSearch ? 'Career Not Found' : 'Career Not Available' }}
                    </h4>
                  </div>
                  <div v-for="(v,k) in data.data" :key="k" class="col-lg-4 col-md-6">
                    <div class="card mb-0 career-card" v-tooltip="v.ac_update_date ? 'Last updated : ' + v.ac_update_date.dates('format2') : 'Created : ' + v.ac_create_date.dates('format2')">
                      <div class="card-body border_card">
                        <div class="product-text">
                          <span class="label label-warning" v-if="v.acc_id=='1'">{{v.acc_name}}</span>
                          <span class="label label-purple" v-if="v.acc_id=='2'">{{v.acc_name}}</span>
                          <span class="label label-info" v-if="v.acc_id=='3'">{{v.acc_name}}</span>

                          <div class="bullet-cta">
                            <router-link :to="{name:'Career', params:{pageSlug:v[idKey]}}"
                              class="bullet_edit mr-1" v-tooltip="'Edit'">
                              <i class="fas fa-pencil-alt"></i>
                            </router-link>
                            <a href="javascript:;" @click="doChangeStatus(k,v)"
                              class="bullet_edit mr-1" v-tooltip="'Change Status'">
                              <i class="fas fa-cog"></i>
                            </a>
                            <a href="javascript:;" class="bullet_delete" v-tooltip="'Delete'"
                              @click="doDelete(k,v)">
                              <i class="far fa-trash-alt"></i>
                            </a>
                          </div>
                          <h4 class="f-600 mt-2"><strong>{{ getShortTxt(v.ac_title, 20) }}</strong> </h4>
                          <p class="mb-2">{{ (v.ac_requirements || '').limitChar(187) }}
                          </p>
                          <div class="d-flex justify-content-between w-100">
                            <span class="label " :class="v.ac_is_active === 'Y' ? 'label-success' : 'label-danger'">
                            {{v.ac_is_active === 'Y' ? 'Active' : 'Inactive'}}</span>
                            <span class=" text-right career-date"><i class="icon-calendar"></i> {{(v.ac_start_date|| '').dates('format4')}} to
                              {{(v.ac_end_date || '').dates('format4')}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg_gray" v-if="data.data!==false&&data.data.length">
                <div class="row">
                  <div class="col-md-12">
                    <div class="pagination-flat float-right">
                      <Pagination :data="data" :limit="4" @pagination-change-page="doPaginate">
                        <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                        <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="seo" role="tabpanel">
            <div class="card" v-if="isMeta">
              <div class="card-body pb-0">
                <div class="form-group row align-items-center mb-0">
                  <label for="" class="col-form-label col-auto">Auto-fill SEO?</label>
                  <div class="col-md">
                    <div class="ia-switch" @click.stop="editAutoFill">
                      <input type="checkbox" v-model="isCheck" name="toggleAutoSeo" id="toggleAutoSeo" class="ia-switch-input">
                      <label for="toggleAutoSeo" class="ia-input-label"></label>
                    </div>
                  </div>
                </div>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSeo">
                <form v-on:submit.prevent="handleSubmit(doSubmitSeo)">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">Meta Title <a
                              v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                              class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                          <input @input="checkDisabledSeo" v-model="rowSEO.sss_title" type="text" id="firstName" class="form-control"
                            placeholder="Meta Title">
                          <VValidate name="Meta Title" v-model="rowSEO.sss_title" :rules="{required: 1, max: 70}" />
                        </div>

                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="" class="control-label">
                            Meta Keyword
                            <a class="help_info" v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                              ?
                            </a>
                            <span class="text-danger mr5">*</span>
                          </label>
                          <input-tag @input="checkDisabledSeo" v-model="rowSEO.sss_keyword"></input-tag>
                          <VValidate name="Keyword" v-model="rowSEO.sss_keyword" rules="required" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">Meta Description <a
                              v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                              class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                          <textarea @input="checkDisabledSeo" v-model="rowSEO.sss_desc" class="form-control" rows="4"></textarea>
                          <VValidate name="Meta Description" v-model="rowSEO.sss_desc" :rules="{required: 1, max: 160}" />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="card-footer">
                    <div class="form-actions text-right">

                      <button @click.prevent="doResetSEO()" id="button-cancel" type="button" class="btn  btn-rounded  btn-secondary" disabled>Cancel</button>

                      <button type="submit" class="btn  btn-rounded  btn-info">Submit</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>

            <div class="card" v-else>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-right">
                  <label class="text-primary">Last updated : {{(rowSEO.sss_update_date||"").dates('format2')}}</label>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Title <a
                        v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <p>{{rowSEO.sss_title}}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="" class="control-label">
                      Meta Keyword
                      <a class="help_info"
                        v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                        ?
                      </a>
                      <span class="text-danger mr5">*</span>
                    </label>
                    <div></div>
                    <template v-for="(v,k) in rowSEO.sss_keyword" >
                      <label class="label label-success mr-1" :key="k">
                        {{v}}
                      </label>
                    </template>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Description <a
                        v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                    <p>{{rowSEO.sss_desc}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="form-actions text-right">
                <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </template>
      <div class="row" v-if="isList">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-12">
          <div class="card">
            <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
              <form v-on:submit.prevent="handleSubmit(doSubmitCRUD,clsMeta)">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="nav nav-tabs customtab" role="tablist">
                      
                      <li class="nav-item"> <a class="nav-link active" @click="changeTab('mainForm')" id="main-nav-dynamic" data-toggle="tab" href="#editor" role="tab"><span
														class="hidden-sm-up"></span> <span class="hidden-xs-down">Career</span></a> </li>
											<li class="nav-item"> <a class="nav-link" @click="changeTab('SEOForm')" id="seo-nav-dynamic" data-toggle="tab" href="#seo" role="tab"><span
														class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
                    
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane active" id="editor" role="tabpanel">
                        <div class="card-body">
                          <div class="form-body">
                            <div class="row">
                              <div class="col-md-8 mt-3">
                                <div class="row">
                                  <div class="col-md-7">
                                    <div class="form-group">
                                      <label class="control-label"> Job Type <span class="text-danger mr5">*</span></label>
                                      <vSelect v-model="row.ac_category" :options="rowcat" :reduce="v => v.acc_id"
                                        placeholder="Select Job Type" label="acc_name"></vSelect>
                                      <VValidate name="Job Type" v-model="row.ac_category" :rules="mrValidation.ac_category"/>
                                    </div>
                                  </div>
                                  <div class="col-md-11">
                                    <div class="form-group">
                                      <label class="control-label">Job Title <span
                                          class="text-danger mr5">*</span></label>
                                      <input type="text" v-model="row.ac_title" id="firstName" class="form-control"
                                        placeholder="Job Title">
                                      <VValidate name="Job Title" v-model="row.ac_title"
                                        :rules="mrValidation.ac_title" />
                                    </div>
                                  </div>
                                  
                                  <!--
                                  <div class="col-md-11">
                                    <div class="form-group">
                                      <label class="control-label">Slug<span class="text-danger mr5">*</span></label>
                                      <div class="input-group">
                                        <input v-model="row.ac_slug"  type="text" id="ac_slug" class="form-control"
                                          placeholder="Slug" :readonly="isReadonly">
                                        <div v-on:click="doSlug()" class="input-group-append finger">
                                          <button type="button" class="btn btn-secondary"><i class="icon-pencil"></i></button>
                                        </div>
                                      </div>
                                      <VValidate name="Slug" v-model="row.ac_slug"
                                        :rules="{required:1, regex:/^[^\s]+$/ }" />
                                    </div>
                                  </div>
                                  -->

                                  <div class="col-md-11">
                                    <div class="form-group">
                                      <label class="control-label">Short Description <span
                                          class="text-danger mr5">*</span></label>
                                      <textarea class="form-control" v-model="row.ac_requirements" rows="4"
                                        placeholder="Short Description"></textarea>
                                      <VValidate name="Short Description" v-model="row.ac_requirements"
                                        :rules="mrValidation.ac_requirements" />
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="row mt-3">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Start Date<span
                                          class="text-danger mr5">*</span></label>
                                      <datepicker input-class="inp-date col-md-12" placeholder="Select Date"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar" :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                                        v-model="row.ac_start_date">
                                      </datepicker>
                                      <span class="ic_apeend"><i class="icon-calender"></i></span>
                                      <VValidate style="position: absolute;" name="Start Date" v-model="row.ac_start_date"
                                        :rules="mrValidation.ac_start_date" />
                                    </div>
                                  </div>

                                  <div class="col-md-12 mt-3">
                                    <div class="form-group">
                                      <label class="control-label">End Date <span
                                          class="text-danger mr5">*</span></label>
                                      <datepicker input-class="inp-date col-md-12" placeholder="Select Date"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="row.ac_end_date" :disabledDates="{ to: new Date(row.ac_start_date || Date.now() - 8640000) }">
                                      </datepicker>
                                      <span class="ic_apeend"><i class="icon-calender"></i></span>
                                      <VValidate style="position: absolute;" name="End Date" v-model="row.ac_end_date"
                                        :rules="mrValidation.ac_end_date" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="control-label">Role & Responsibilities <span
                                      class="text-danger mr5">*</span></label>
                                  <CKEditor 
                                    :value.sync="row.ac_role"
                                  />
                                  <!-- <tinymce id="role1" @editorInit="tmEditorInitRole(row.ac_role, 'reff')" v-model="row.ac_role"
                                    ref="tm"
                                    toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                    :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'>
                                  </tinymce> -->
                                  <VValidate name="Role" v-model="row.ac_role" :rules="mrValidation.ac_role" />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="control-label">Qualification <span
                                      class="text-danger mr5">*</span></label>
                                  <CKEditor 
                                    :value.sync="row.ac_qualification"
                                  />
                                  <!-- <tinymce id="qual" @editorInit="tmEditorInit(row.ac_qualification)"
                                    v-model="row.ac_qualification" ref="tm"
                                    toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                    :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'>

                                  </tinymce> -->
                                  <VValidate name="Qualification" v-model="row.ac_qualification"
                                    :rules="mrValidation.ac_qualification" />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="control-label">
                                    Status
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <div>
                                    <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                                    <InputRadio v-model="row[statusKey]" name="status" option="N" />
                                  </div>
                                  <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="seo" role="tabpanel">
                          
                          <div class="card-body" v-if="!isMeta">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Title <a
                                      v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                      <p>{{row.ac_meta_title}}</p>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="" class="control-label">
                                    Meta Keyword
                                    <a class="help_info"
                                      v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                      ?
                                    </a>
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <div></div>
                                  <template v-for="(v,k) in row.ac_meta_keyword" >
                                    <label class="label label-success mr-1" :key="k">
                                      {{v}}
                                    </label>
                                  </template>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Description <a
                                      v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <p>{{row.ac_meta_description}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                            
                          <div class="card-body" v-else>
                            <div class="row" style="width: 100%">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Title
                                    <a class="help_info" v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'">?</a> <span class="text-danger mr5">*</span>
                                  </label>
                                  <input v-model="row.ac_meta_title" type="text" class="form-control" placeholder="Meta Title">
                                  <VValidate name="Meta Title" v-model="row.ac_meta_title" :rules="{required: mrValidation.ac_meta_title, max: 70}" />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Keyword
                                    <a class="help_info" v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'"
                                      >
                                      ?</a> <span class="text-danger mr5">*</span>
                                  </label>
                                  <input-tag v-model="row.ac_meta_keyword"
                                    placeholder="'Type and press enter to add'" :taggable="true">
                                    <span class="d-none" slot="no-options" @click="$refs.select.open = false"></span>
                                  </input-tag>
                                  <VValidate name="Meta Keyword" v-model="row.ac_meta_keyword" :rules="mrValidation.ac_meta_keyword" />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Description
                                    <a class="help_info" v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'">
                                      ?</a> <span class="text-danger mr5">*</span></label>
                                  <textarea v-model="row.ac_meta_description" class="form-control"
                                    placeholder="Meta Description" rows="4">Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. but the majority have suffered alteration in some form, by injected humour</textarea>
                                  <VValidate name="Meta Description" v-model="row.ac_meta_description" :rules="{required: mrValidation.ac_meta_description, max: 160}" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="form-actions text-right" v-if="tab == 'SEOForm'">
                    <button type="button" @click="cancelButton()" class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                    <template v-if="isMeta">
                      <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                    </template>
                    <template v-else>
                      <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                    </template>
                  </div>
                  
                  <div class="form-actions text-right" v-else>
                    <button type="button" @click="cancelButton()" class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                    <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalVue from '@libs/Global'
  import Gen from '@libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'
  import PageTitle from '@view/include/PageTitle'
  import CKEditor from '@/components/CKEditor'

  let $ = global.jQuery
  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      Datepicker,
      PageTitle,
      CKEditor
    },
    data() {
      return {
        idKey: 'ac_id',
        statusKey: 'ac_is_active',
        mrLevel: [],
        row_hero: {},
        metaLatest: {},
        mrValidationH: {},
        rowcat: [],
        DatePickerFormat: 'dd/MM/yyyy',
        showList: false,
        rowSEO : {},
        rowSEOtemp: {},
        isReadonly:true,
        total_data: '',
        isSearch : false,
        isMeta : false,
        isCheck: false, 
        tab: '',
        TabSeo : JSON.parse(localStorage.getItem("SEO"))
      }
    },
    mounted() {
      this.apiGet()
      this.apiGetHeroImage()
      this.apiGetCareerCat()
      this.apiGetSEO();
      
      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        $("#editor").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav").removeClass("active");
        $("#seo-nav").addClass("active");
      }

      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.valChild === "Y"){
        this.tab = 'SEOForm'
        
        $("#editor").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav-dynamic").removeClass("active");
        $("#seo-nav-dynamic").addClass("active");
        
      }

      setTimeout(() => {
        $('.vs__open-indicator').attr('type', 'button')
        if(this.$route.query.search){        
          if (this.filter && this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          } 
        }
      }, 300)
      
      if(this.isList){
        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      }

      this.seoSettings=false

    },
    methods: {
      clsMeta(){
        this.isMeta = true  
      },
      editMeta(){
        this.isMeta = true
        this.isCheck = false
      },
      checkDisabledSeo() {
        $('#button-cancel').removeAttr('disabled')
      },
      cancelButton(){
        global.Swal.fire({
          icon: 'warning',
          title: 'Are You Sure to Cancel?',
          text: 'You will not able to recover this data.',
          showCancelButton: true,
          confirmButtonText: `Yes, Cancel`,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then(res => {  
          if (res.value) {
            let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
            localStorage.setItem("SEO", confSeo)
            this.$router.push({
              name: this.$route.name
            })
          }
        })
      },
      editAutoFill(){
        if(!this.isCheck){
          global.Swal.fire({
            icon: 'warning',
            title: 'Change back SEO Content to Auto-fill Mode?',
            text: 'Any changes you made will be lost!',
            showCancelButton: true,
            confirmButtonText: `Yes, change it!`,
            cancelButtonText: 'No Cancel',
            customClass: {
              confirmButton: 'btn btn-custom',
              cancelButton: 'btn btn-outline-custom ml-1',
            },
            buttonsStyling: false, 
          }).then(res => {      
            if (res.value) {
              this.isCheck = true
              this.rowSEO = {
                sss_id : this.rowSEO.sss_id,
                sss_title : this.metaLatest.ac_meta_title,
                sss_desc : this.metaLatest.ac_meta_description,
                sss_keyword : this.metaLatest.ac_meta_keyword
              }
              $('#button-cancel').attr('disabled', 'disabled')
            }
            else{
              this.isCheck = false
            }
          })
        }
        else{
          setTimeout(() => {
            this.rowSEO = {...this.rowSEOtemp}
          }, 0)    
        }
      },
      apiGet(params = {}){
        if(this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/"+this.modulePage+
          (this.pageSlug?"/"+this.pageSlug:"")+
          (this.pageId?"/"+this.pageId:""), 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })

          setTimeout(() => {
            if(!this.isAdd) this.row.ac_category = +this.row.ac_category
          }, 0)
          
          
          if(!this.isList){
            this.setFocusForm()
          }
          
          if(params.callback) params.callback()
        }).catch(()=>{
          this.loadingOverlay = false
        })
      },
      doSearch (){
        this.isSearch = true
        setTimeout(() => {
          $('#btn-search').removeAttr("disabled")
        }, 0)
      },
      clsSearch(){
          this.isSearch = false
          setTimeout(() => {
            $('#btn-search').attr("disabled", "disabled")
          }, 0)
      },
      our(){
        this.seoSettings=false
      },
      seo(){
        this.seoSettings=true
      },
      doSlug(){
        if(this.isReadonly){
          this.isReadonly = false
        } else {
          this.isReadonly = true
        }
      },
      doValidate(){
        if(this.row.ac_meta_title.length > 70 || this.row.ac_meta_description.length > 160 || !this.row.ac_meta_title || !this.row.ac_meta_description || !this.row.ac_meta_keyword){
          $("#editor").removeClass("active");
          $("#seo").addClass("active");
          $("#main-nav-dynamic").removeClass("active");
          $("#seo-nav-dynamic").addClass("active");
        }else if(!this.row.ac_category || !this.row.ac_title || !this.row.ac_requirements || !this.row.ac_role || !this.row.ac_qualification || !this.row.ac_start_date || !this.row.ac_end_date){
          $("#editor").addClass("active");
          $("#seo").removeClass("active");          
          $("#main-nav-dynamic").addClass("active");
          $("#seo-nav-dynamic").removeClass("active");
          return;
        }
      },
      // SEO
      apiGetSEO() {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "StaticSEO/7"
        ).
        then(res => {
            this.loadingOverlay = false
            this.rowSEO = res.data.row;
            this.rowSEOtemp = res.data.row;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doResetSEO(){
        this.rowSEO = {
          ...this.rowSEOtemp
        }    

        this.isCheck = false
        $('#button-cancel').attr('disabled', 'disabled')
      },
      
      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#editor").addClass("active").addClass("show");

        $("#seo-nav").removeClass("active");
        $("#main-nav").addClass("active");

        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },

      doSubmitSeo(IdFormSeo = 'VFormSeo') {
        this.rowSEO.sss_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + "StaticSEO",
          _.assign({
            type: 'update',
            id: this.rowSEO.sss_id
          }, this.rowSEO),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.doBackSuccessMeta()
              this.rowSEO = resp.data
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSeo
        )
      },
      apiGetHeroImage: function () {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "HeroImageCareer"
        ).
        then(res => {
            this.loadingOverlay = false
            this.row_hero = res.data.row_hero;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doSubmitHero() {
        this.row_hero.ahi_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateHeroImage',
            id: this.row_hero.ahi_id
          }, this.row_hero),
          (type, resp) => {
            if (type == 'success') {
              this.row_hero = resp.data
              $('#collapseExample').removeClass('show');
              this.$router.push({
                name: this.$route.name
              })
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      doDeleteImg() {
        this.row_hero.ahi_background = '';
      },
      apiGetCareerCat: function () {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "CareerCat"
        ).
        then(res => {
            this.loadingOverlay = false
            this.rowcat = res.data.rowcat
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      checkDisabled() {
        if (this.filter && this.filter.search.length <= 0) {
          $('#btn-search').attr("disabled", "disabled")
        } else {
          $('#btn-search').removeAttr("disabled")
        }
      },
      cleanMultipleDash(string){
        string = string.replace(/-{2,}/g, '-');
        return string;
      },
      tmEditorInitRole(val, reff = null){
        if(reff === 'reff'){
          this.$refs.reff.editor.setContent(val);
          return;
        }
      },
      changeTab(tab){
        this.tab = tab
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }
        else if(this.tab === "SEOForm"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N", valChild: "Y"})
          localStorage.setItem("SEO", confSeo)
        }
        else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }
      },
    },
    watch: {
      'row.ac_title'(v) {
        this.titleLength = v.length
        // this.row.ac_slug = this.cleanMultipleDash(v.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,''))
        
        if(this.isAdd){
          this.row.ac_meta_title = v
          this.row.ac_meta_keyword = [v]        
        }
      },
      'row.ac_requirements'(v) {
        if(this.isAdd){
          this.row.ac_meta_description = v
        }
      },

      // 'row.ac_slug'(v){
      //   this.row.ac_slug = this.cleanMultipleDash(v.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,''))
      // },
      $route() {
        this.apiGet()
        this.tab = 'main'
        
        if (this.isAdd) {
          this.isMeta = true
        }
        else{
          this.isMeta = false
        }
        
        if (!this.isList) {
          this.isSearch = false
          this.filter = {
            category: 0,
            status: 0
          }
        } else {
          this.filter = {
            category: 0,
            status: 0,
            ...this.filter
          }
          let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
          localStorage.setItem("SEO", confSeo)        
          // $('#btn-search').removeAttr("disabled")
        }
        if (this.$route.params.pageSlug) {
          this.showList = true
        } else {
          this.showList = false
        }


        setTimeout(() => {
          $('.vs__open-indicator').attr('type', 'button')
        }, 300)
        
      },
      'row.ac_end_date'(v) {
        if (v < this.row.ac_start_date) {
          global.Swal.fire({
            title: 'End date must be greater than start date',
            icon: 'warning',
          })
          this.row.ac_end_date = null;
        }
      },
      'row_hero.ahi_title'(v){
        this.row_hero.ahi_alt = v
      },
    }
  }
</script>